import { Circles } from "react-loader-spinner"
import React from "react"

const Spinner = ({ colorSpin, height, width }) => {
  return (
    <Circles
      color={colorSpin === undefined ? "var(--bs-warning)" : colorSpin}
      width={width}
      height={height}
    />
  )
}

export default Spinner
